.mapsupport {
    position: fixed; // Đặt vị trí cố định nếu cần
    bottom: 120px; // Vị trí từ dưới lên
    right: 20px; // Vị trí từ phải sang
    cursor: pointer; // Hiển thị con trỏ chuột khi di chuột qua

    .mapicon {
        display: flex;
        align-items: center;
        background-color: #fff; // Màu nền trắng
        border-radius: 50%; // Hình tròn
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        padding: 10px;
        transition: background-color 0.3s;

        &:hover {
            background-color: #f0f0f0; // Màu nền khi hover
        }
    }

    .mapimage {
        width: 40px; // Kích thước hình ảnh
        height: 40px; // Kích thước hình ảnh
    }

    .maptext {
        display: none;
        margin-left: 10px; 
        font-weight: bold; 
        @media (min-width: 769px) { 
            display: block; 
          }
    }
}