.smsButton {
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 360px;
    right: 20px;
    cursor: pointer;
    // background-color: white; /* Màu nền của nút */
    // border-radius: 50px;
    // padding: 10px 5px 10px 10px;
    // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    &:hover {
        background-color: #e0e0e0; /* Màu nền khi hover */
    }
}

.smsImage {
    width: 40px; /* Kích thước biểu tượng */
    height: 40px;
    margin-right: 8px; /* Khoảng cách giữa biểu tượng và văn bản */
}

.smsText {
    display: none;
    font-size: 16px; /* Kích thước chữ */
    @media (min-width: 769px) { 
        display: block; 
      }
}
@media (min-width: 769px) {
    .smsButton {
        display: none; /* Ẩn nút khi màn hình lớn hơn 769px */
    }
}