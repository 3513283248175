.sitefooter {
  display: block;
  unicode-bidi: isolate;
  margin-top: 25px;
  width: 100%;

  .siteinfo {
    text-align: center;
    font-size: 15px;
    color: #2f4468;
    margin-left: -20px;

    .inside-site-info {
      display: flex;
      align-items: center;
      justify-content: center;

      &.gridcontainer {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
      }
    }
  }
}