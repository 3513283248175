.fixedPhoneLink {
  display: none; // Ẩn mặc định

  @media (max-width: 768px) {
      display: flex; // Hiển thị trên màn hình nhỏ
      position: fixed; // Cố định vị trí
      bottom: 0; // Đặt ở phía dưới
      left: 0; // Đặt ở phía bên trái
      right: 0; // Đặt ở phía bên phải
      max-width: 100vw;
      background-color: #007bff; // Màu nền (có thể thay đổi)
      color: white; // Màu chữ
      justify-content: center; // Căn giữa nội dung
      padding: 15px; // Khoảng cách bên trong
      text-align: center; // Căn giữa văn bản
      z-index: 1000; // Đảm bảo nó hiển thị trên các phần tử khác
  }
}

.phoneText {
  color: white; // Màu chữ
  text-decoration: none; // Bỏ gạch chân
  font-size: 16px; // Kích thước chữ (có thể điều chỉnh)
}

.contactsupport {
  position: fixed; // Đặt vị trí cố định
  bottom: 140px; // Vị trí từ dưới lên
  right: 30px; // Vị trí từ phải sang
  cursor: pointer; // Hiển thị con trỏ chuột khi di chuột qua

  .contacticon {
      display: flex;
      align-items: center;
      // background-color: #fff; // Màu nền trắng
      // border-radius: 50%; // Hình tròn
      // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      // padding: 10px;
      transition: background-color 0.3s;

      &:hover {
          background-color: #f0f0f0; // Màu nền khi hover
      }
  }

  .contactimage {
      width: 45px; // Kích thước hình ảnh
      height: 50px; // Kích thước hình ảnh
      border-radius: 50%;
  }

  .contacttext {
      display: none; 
      margin-left: 10px; 
      font-weight: bold; 
      @media (min-width: 769px) { 
          display: block; 
      }
  }
  @keyframes shake {
    0% { transform: rotate(0deg); }     /* Giữ nguyên tại 0 độ */
  30% { transform: rotate(0deg); }    /* Giữ nguyên tại 0 độ */
  40% { transform: rotate(15deg); }   /* Nghiêng sang phải */
  50% { transform: rotate(0deg); }    /* Trở về 0 độ */
  60% { transform: rotate(-15deg); }  /* Nghiêng sang trái */
  70% { transform: rotate(0deg); }    /* Trở về 0 độ */
  80% { transform: rotate(0deg); }    /* Giữ nguyên tại 0 độ */
  100% { transform: rotate(0deg); }    /* Giữ nguyên tại 0 độ */
}

@media (max-width: 768px) { /* Điều chỉnh kích thước theo nhu cầu */
  .shake {
    animation: shake 0.8s ease infinite;
    animation-delay: 2s; /* Thêm độ nghỉ sau khi đã rung */
  }
}

}