.container {
  width: 100%;
  background-color: #f3f4f6;
  height: 100vh;
  @media (max-width: 768px) {
    height: auto; // Đặt chiều cao tự động trên thiết bị di động
    padding: 20px; // Thêm padding để nội dung không chật chội
    
  }

  .buttonText {
    background: #242226;
    border: 1px solid transparent;
    cursor: pointer;
    padding: 10px 10px;
    margin-top: -5px;
    color: #ffffff;
    
    &:hover {
      background-color: #55555e;
    }
  }
}