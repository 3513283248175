.headertitle {
  justify-content: flex-start;
  background-color: #ffffff;
  width: 58%;
  margin-left: 130px;
  margin-top: 50px;
  padding-bottom: -10px;
  border-right: 2px solid rgba(0, 0, 0, 0.07);
  border-bottom: 2px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 10px rgba(232, 234, 237, 0.5);
  @media (max-width: 768px) {
    width: 100%;
    max-width: 90vw; // Đặt chiều rộng 100% trên thiết bị di động
    margin: 0; // Bỏ lề trên và dưới
    padding: 10px; // Thêm padding nếu cần
  }

  .title {
    h1 {
      font-weight: 500;
      font-size: 30px;
      color: #2F4468;
      font-family: "Open Sans", sans-serif;
      margin-bottom: 0;
      margin-left: 40px;
    }

    .dropdownBox {
      background: #f9f9f9;
      border: 1px solid #aaa;
      box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
      display: table;
      margin-bottom: 1em;
      padding: 10px 20px 10px 10px;
      position: relative;
      width: auto;
      margin-left: 45px;
      margin-top: 10px;
      border-radius: 5px;

      a {
        color: #212121;
        font-size: 120%;
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        display: inline-flex;
        text-align: left;
        line-height: 1.45;
        margin: 0;
        padding: 0;
        vertical-align: middle;
        text-decoration: none;

        .togglespan {
          display: inline-flex;
          align-items: center;
          margin-left: 8px;
          gap: 4px;
          width: auto;
          height: auto;
          justify-content: center;
          border: 1px solid #333;
          margin-left: 23px;
          border-radius: 3px;

        }
      }

      .dropdownBoxMenu {
        a {
          color: #444;
          box-shadow: none;
          text-decoration: none;
          text-shadow: none;
          display: inline-flex;
          align-items: stretch;
          flex-wrap: nowrap;
          font-size: 16.5px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .mainTitle {
    margin-left: 45px;

    .mainTitleHeader {
      h2 {
        color: #008000;
        font-weight: 600;
        font-size: 30px;
        line-height: 1.2em;
        text-transform: none;
        font-family: "Open Sans", sans-serif;
      }

      p {
        margin-bottom: 1.5em;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;

        strong {
          font-weight: 700;

          a {
            text-decoration: none;
            color: #1b78e2;
          }

          .linkto:hover {
            text-decoration: underline;
          }
        }
      }

      h3 {
        font-size: 20px;
        line-height: 1.2em;
        font-weight: 400;
        text-transform: none;
        margin-bottom: 20px;
      }

      .contractcontainer {
        div {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        .phone-icon {
          display: flex;
          align-items: center;

          img {
            display: block;
          }
        }
      }

      .hoverWord {
        color: #1b78e2;

        :hover {
          color: #212121;
        }
      }
    }
  }
}

.sitefooter {
  display: block;
  unicode-bidi: isolate;
  margin-top: 25px;

  .siteinfo {
    text-align: center;
    font-size: 15px;
    color: #2f4468;
    margin-left: -20px;

    .inside-site-info {
      display: flex;
      align-items: center;
      justify-content: center;

      &.gridcontainer {
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
      }
    }
  }
}

.zalosupport {
  position: fixed;
  text-align: end;
  margin-top: -100px;
  margin-right: 30px;

  .zaloicon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 50px;
    padding: 10px 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    cursor: pointer;
    transition: all 0.3s ease;

    .zaloimage {
      width: 60px;
      height: 60px;
    }

    .zalotext {
      display: block;
      color: #007aff;
      font-weight: bold;
      margin-top: 5px;
    }
  }

  .qrmodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .qrcontent {
      background: white;
      padding: 20px;
      border-radius: 10px;
      text-align: center;

      h3 {
        margin-bottom: 10px;
      }

      .closebutton {
        margin-top: 15px;
        padding: 10px 20px;
        background: #007aff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background: #005bb5;
        }
      }
    }
  }
}

.carousel {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 90vw; // Đặt chiều rộng 100% trên thiết bị di động
  }

  .slide {
    display: flex;
    justify-content: flex-start;
    /* Align items to the left */
    align-items: center;
    /* Vertically center the image and text */
    padding: 20px;
    gap: 30px;
    /* Add space between image and text */
    flex-wrap: nowrap;
    /* Ensure text stays next to the image */

    img {
      width: 50%;
      height: auto;
      max-height: 400px;
      /* Optional: Adjust the image max height */
      object-fit: cover;
      border-radius: 8px;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
        /* Slight zoom effect on hover */
      }
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #002855;
      width: 50%;

      .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 15px;
        text-transform: uppercase;
      }

      .description {
        font-size: 16px;
        color: #444;
        line-height: 1.5;
        max-width: 100%;
        text-align: left;
      }
    }
  }

  .control-arrow {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  .carousel-indicators {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
    gap: 8px;

    .dot {
      width: 10px;
      height: 10px;
      background-color: #ccc;
      border-radius: 50%;
      cursor: pointer;

      &.active {
        background-color: #002855;
      }
    }
  }

  @media (max-width: 1200px) {
    .slide {
      gap: 20px;
      padding: 15px;
    }

    .textContainer {
      width: 50%;

      .title {
        font-size: 22px;
      }

      .description {
        font-size: 14px;
      }
    }

    img {
      width: 50%;
      max-height: 350px;
    }
  }

  @media (max-width: 768px) {
    .slide {
      flex-direction: column;
      gap: 15px;
    }

    img {
      width: 100%;
      max-height: 300px;
    }

    .textContainer {
      width: 100%;
      text-align: center;
    }

    .title {
      font-size: 20px;
    }

    .description {
      font-size: 16px;
      padding: 0 20px;
    }
  }

  @media (max-width: 480px) {
    .slide {
      gap: 10px;
    }

    .textContainer {
      .title {
        font-size: 18px;
      }

      .description {
        font-size: 14px;
      }
    }

    img {
      max-height: 250px;
    }
  }
}