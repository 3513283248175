.zalosupport {
  position: fixed;
  text-align: end;
  @keyframes shake {
    0% { transform: rotate(0deg); }     /* Giữ nguyên tại 0 độ */
  30% { transform: rotate(0deg); }    /* Giữ nguyên tại 0 độ */
  40% { transform: rotate(15deg); }   /* Nghiêng sang phải */
  50% { transform: rotate(0deg); }    /* Trở về 0 độ */
  60% { transform: rotate(-15deg); }  /* Nghiêng sang trái */
  70% { transform: rotate(0deg); }    /* Trở về 0 độ */
  80% { transform: rotate(0deg); }    /* Giữ nguyên tại 0 độ */
  100% { transform: rotate(0deg); }    /* Giữ nguyên tại 0 độ */
}

@media (max-width: 768px) { /* Điều chỉnh kích thước theo nhu cầu */
  .shake {
    animation: shake 0.8s ease infinite;
    animation-delay: 2s; /* Thêm độ nghỉ sau khi đã rung */
  }
}
  // margin-top: -100px;
  // margin-right: 30px;
  // display: none; 

  // @media (min-width: 769px) { // Chỉ hiển thị trên thiết bị PC
  //   display: flex; // Hiển thị nếu chiều rộng >= 769px
  // }


  .zaloicon {
    position: fixed;
    bottom: 210px;
    right: 30px;
    display: flex;
    align-items: center;
    // background-color: #ffffff;
    // border: 1px solid #e0e0e0;
    // border-radius: 50px;
    // padding: 12px ;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    cursor: pointer;
    transition: all 0.3s ease;

    .zaloimage {
      width: 45px;
      height: 50px;
    }

    .zalotext {
      display: none;
      color: #007aff;
      font-weight: bold;
      margin-top: 5px;
      @media (min-width: 769px) { 
        display: block; 
      }
    }
  }

  .qrmodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .qrcontent {
      background: white;
      padding: 20px;
      border-radius: 10px;
      text-align: center;

      h3 {
        margin-bottom: 10px;
      }

      .closebutton {
        margin-top: 15px;
        padding: 10px 20px;
        background: #007aff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background: #005bb5;
        }
      }
    }
  }
}